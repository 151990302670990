// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-resposta-js": () => import("./../../../src/pages/contato-resposta.js" /* webpackChunkName: "component---src-pages-contato-resposta-js" */),
  "component---src-templates-colaborador-js": () => import("./../../../src/templates/colaborador.js" /* webpackChunkName: "component---src-templates-colaborador-js" */),
  "component---src-templates-contato-page-js": () => import("./../../../src/templates/contato-page.js" /* webpackChunkName: "component---src-templates-contato-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-portfolio-comercial-js": () => import("./../../../src/templates/portfolio-comercial.js" /* webpackChunkName: "component---src-templates-portfolio-comercial-js" */),
  "component---src-templates-portfolio-comercial-page-js": () => import("./../../../src/templates/portfolio-comercial-page.js" /* webpackChunkName: "component---src-templates-portfolio-comercial-page-js" */),
  "component---src-templates-portfolio-residencial-js": () => import("./../../../src/templates/portfolio-residencial.js" /* webpackChunkName: "component---src-templates-portfolio-residencial-js" */),
  "component---src-templates-portfolio-residencial-page-js": () => import("./../../../src/templates/portfolio-residencial-page.js" /* webpackChunkName: "component---src-templates-portfolio-residencial-page-js" */),
  "component---src-templates-quem-somos-page-js": () => import("./../../../src/templates/quem-somos-page.js" /* webpackChunkName: "component---src-templates-quem-somos-page-js" */)
}

