export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )

  if (answer === true) {
    window.location.reload()
  }
}

const $ = require("jquery")
window.jQuery = $

export const onInitialClientRender = () => {
  $( document ).ready(function() {
    $('.box-hover').hover(function(e){
          $(this).children('.hover-box').fadeIn(250);
          e.preventDefault();
      }, function(e){
          $(this).children('.hover-box').fadeOut(250);
          e.preventDefault();
      });
  
      $('ul.nav li.dropdown').hover(function(e) {
          $(this).children('.dropdown-menu').stop(true, true).delay(200).fadeIn();
          e.preventDefault();
      }, function(e) {
          $(this).children('.dropdown-menu').stop(true, true).delay(200).fadeOut();
          e.preventDefault();
      });
  
      $('.dropdown-submenu').hover(function(e) {
          $(this).children('.dropdown-menu').stop(true, true).delay(100).fadeIn();
          e.preventDefault();
      }, function(e) {
          $(this).children('.dropdown-menu').stop(true, true).delay(100).fadeOut();
          e.preventDefault();
      });
  
      $('.box-equipe').click(function(){
          var descricao = $(this).find('.hover-descricao');
          var box = $(this);
          $('.ativo').removeClass('ativo');
          box.children('.hover').addClass('ativo');
      $('.descricao-membro').fadeOut(300, function(){
        $('.descricao-membro').html( descricao.html() ).fadeIn(1000);	
          });
          $('html,body').animate({
              scrollTop: $('.equipe').offset().top - 60
          }, 500);
      });
  
  });

  $(window).on('load', function(){
    $('input:checkbox').removeAttr('checked');
    $('input:radio').removeAttr('checked');
  });

  $('input[name=tipo]').on('change', function(){
    
    if ($(this).val() === "res") {
      $('.fields-container').animate({opacity:0}, 200, function(){
          $('.fields-container .teste1').removeClass('hidden');
          $('.fields-container .teste2').addClass('hidden');
          $('.fields-container').animate({opacity:1}, 200);

      });
    } else if($(this).val() === "com"){
      $('.fields-container').animate({opacity:0}, 200,function(){

        $('.fields-container .teste2').removeClass('hidden');
        $('.fields-container .teste1').addClass('hidden');
        $('.fields-container').animate({opacity:1}, 200);
      });
    }
  });

  var $animation_elements = $('.animation-element');
  var $window = $(window);

  function check_if_in_view() {
    var window_height = $window.height()-100;
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function() {
        var $element = $(this);
        var element_height = $element.outerHeight();
        var element_top_position = $element.offset().top;
        var element_bottom_position = (element_top_position + element_height);
        
        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('in-view');
        }
    });
  }

  $window.on('scroll resize', check_if_in_view);
  $window.trigger('scroll');
}
